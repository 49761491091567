import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import React from "react"

import FeaturedImage from "../components/Image/FeaturedImage"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

type PostType = {
  wpPost: {
    content: string
    featuredImage: {
      node: {
        sourceUrl: string
      }
    }
    title: string
  }
}

type Props = { data: PostType }

const orangeLogoWidth = 100

const useStyles = makeStyles((theme) => ({
  postContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingLeft: `${orangeLogoWidth}px`,
    paddingRight: `${orangeLogoWidth}px`,
  },
  postText: {
    a: {
      color: theme.palette.primary.main,
    },
  },
}))

export default function Post({ data }: Props) {
  const {
    wpPost: { content, featuredImage, title },
  } = data

  const classes = useStyles()
  const sourceUrl = featuredImage?.node?.sourceUrl

  return (
    <Layout>
      <Seo />
      <Container className={classes.postContainer}>
        <FeaturedImage sourceUrl={sourceUrl} />
        <Typography
          component="h3"
          dangerouslySetInnerHTML={{ __html: title }}
          variant="h3"
        />
        <Typography
          className={classes.postText}
          component="div"
          dangerouslySetInnerHTML={{ __html: content }}
          variant="body1"
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      content
      id
      featuredImage {
        node {
          sourceUrl
        }
      }
      title
    }
  }
`
